import React, { useState } from 'react';
import { TextField, Typography, Box } from '@mui/material';

const ExtractLatLng = ({ setCordinatesFromUrl }: any) => {

    const [url, setUrl] = useState('');
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

    const extractLatLng = (inputUrl: string) => {
        try {
            const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
            const match = inputUrl.match(regex);
            if (match) {
                return { lat: parseFloat(match[1]), lng: parseFloat(match[2]) };
            }
        } catch (error) {
            console.error('Error extracting coordinates:', error);
        }
        return { lat: null, lng: null };
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setUrl(inputValue);
        const coords: any = extractLatLng(inputValue);
        setCoordinates(coords);
        setCordinatesFromUrl(coords);
    };

    return (
        <Box sx={{ maxWidth: 400, margin: '0px auto' }}>
            <TextField
                fullWidth
                sx={{ mt: -1 }}
                label="Paste Google Maps URL"
                value={url}
                onChange={handleInputChange}
                variant="outlined"
                margin="normal"
            />
        </Box>
    );
};

export default ExtractLatLng;
