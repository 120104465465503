import React, { FC } from 'react';
import { useNotify, ListBase, useListContext, Pagination } from 'react-admin';
import {
    Box, Typography, Avatar, Button, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    MenuItem,
    InputLabel,
    Select,

} from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const UserList = () => {
    return (
        <ListBase
            resource="users"
            perPage={10}
            sort={{ field: 'date', order: 'DESC' }}
            filter={{}}
        >
            <UsersSettings />
        </ListBase>
    );
};

const UsersSettings = () => {

    const notify = useNotify();
    const [state, setState] = React.useState(false);

    const toggleDrawerOpen = () => {
        setState(true);
    };

    const toggleDrawerClose = () => {
        setState(false);
    };

    const { data: users, total, isLoading, error } = useListContext();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [newUser, setNewUser] = React.useState({
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        user_role: ""
    });
    const [errors, setErrors] = React.useState<any>({});

    const handleDialogOpen = () => setOpenDialog(true);
    const handleDialogClose = () => {
        setOpenDialog(false);
        setNewUser({ email: "", firstname: "", lastname: "", password: "", user_role: "" });
        setErrors({});
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    const validate = () => {
        const errors: any = {};
        if (!newUser.email) errors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(newUser.email)) errors.email = "Email is invalid";
        if (!newUser.firstname) errors.firstname = "First name is required";
        if (!newUser.lastname) errors.lastname = "Last name is required";
        if (!newUser.password) errors.password = "Password is required";
        else if (newUser.password.length < 6) errors.password = "Password must be at least 6 characters";
        if (!newUser.user_role) errors.user_role = "Role is required";
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleAddUser = () => {
        if (validate()) {
            console.log("New user data:", newUser);
            handleDialogClose();
        }
    };

    return (
        <>
            <Box>
                <Avatar sx={{ bgcolor: lightBlue[500] }} variant="rounded">
                    <AccountCircleIcon />
                </Avatar>
            </Box>
            <Box>
                <Typography sx={{ fontSize: 18, color: "#ddd", mt: -0.5 }}>Users</Typography>
                <Typography sx={{ fontSize: 11, color: "gray", mb: 1 }}>Change your account settings</Typography>
                <Button variant="text" color="warning" startIcon={<AccountCircleIcon />} onClick={toggleDrawerOpen}>
                    Users & Roles
                </Button>
            </Box>
            <Drawer
                anchor="right"
                open={state}
                onClose={toggleDrawerClose}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        backgroundColor: "#000"
                    }
                }}
            >
                <Box sx={{ p: 5, width: 600 }}>
                    <AccountCircleIcon sx={{ color: "white", fontSize: 45 }} />
                    <Typography sx={{ color: "white", fontSize: 20, mt: 1 }}>Users & Roles</Typography>
                    {/* <Box sx={{ mt: 2, mb: 2 }}>
                        <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                            Add New User
                        </Button>
                    </Box> */}
                    <Box sx={{ mt: 1, mb: 1 }}></Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="right">Full Name</TableCell>
                                    <TableCell align="right">Role</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users?.map((row) => (
                                    <TableRow key={row.email}>
                                        <TableCell component="th" scope="row">
                                            {row.email}
                                        </TableCell>
                                        <TableCell align="right">{row.firstname + " " + row.lastname}</TableCell>
                                        <TableCell align="right">
                                            <Chip label={row.user_role} color="primary" />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination />
                </Box>

                <Dialog open={openDialog} onClose={handleDialogClose} PaperProps={{
                    sx: {
                        backgroundColor: "#000",
                        borderRadius: 5
                    }
                }}>
                    <DialogTitle>Add New User</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            value={newUser.email}
                            onChange={handleInputChange}
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                        />
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <TextField
                                margin="dense"
                                name="firstname"
                                label="First Name"
                                type="text"
                                fullWidth
                                value={newUser.firstname}
                                onChange={handleInputChange}
                                error={Boolean(errors.firstname)}
                                helperText={errors.firstname}
                            />
                            <TextField
                                margin="dense"
                                name="lastname"
                                label="Last Name"
                                type="text"
                                fullWidth
                                value={newUser.lastname}
                                onChange={handleInputChange}
                                error={Boolean(errors.lastname)}
                                helperText={errors.lastname}
                            />
                        </Box>
                        <TextField
                            margin="dense"
                            name="password"
                            label="Password"
                            type="password"
                            fullWidth
                            value={newUser.password}
                            onChange={handleInputChange}
                            error={Boolean(errors.password)}
                            helperText={errors.password}
                        />
                        <FormControl fullWidth margin="dense" error={Boolean(errors.user_role)} sx={{ width: 170 }}>
                            <InputLabel>Role</InputLabel>
                            <Select
                                name="user_role"
                                value={newUser.user_role}
                                onChange={handleInputChange}
                                label="Role"
                            >
                                <MenuItem value="Admin">Admin</MenuItem>
                                <MenuItem value="User">User</MenuItem>
                                <MenuItem value="Viewer">Viewer</MenuItem>
                            </Select>
                            {errors.user_role && (
                                <Typography color="error" variant="caption">
                                    {errors.user_role}
                                </Typography>
                            )}
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleAddUser} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </Drawer>
        </>
    );
};

export default UserList;