import * as React from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Button, Typography, TextField, Checkbox, FormControlLabel, MenuItem, Select, InputLabel, Autocomplete } from '@mui/material';
import { useCreate, usePermissions, useNotify, useRefresh, useGetList } from 'react-admin';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";

const CreateDriver = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { permissions } = usePermissions();

    const [vehicleFilter, setVehicleFilter] = React.useState(0);

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const [create, { isLoading, error, data }] = useCreate();

    const { data: vehicles, total, isLoading: isLoadingVehicles, error: errorVehicles, refetch } = useGetList(
        'vehicles',
        {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'date', order: 'DESC' },
            filter: {}
        }
    );

    const { control, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {
        data.status = "AVAILABLE";
        data.vehicle_id = vehicleFilter;
        create('drivers', { data: data });
    };

    if (data) {
        notify("New driver added successfully", { type: "success" });
        navigate("/taxi-drivers");
        refresh();
    }

    if (error) {
        notify("Driver was not added successfully", { type: "error" });
    }

    return (
        <Box sx={{ mt: isSmall ? 0 : 7, p: 4, background: "linear-gradient(145deg, #222, #333)", borderRadius: 10, ml: 4, mr: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography fontSize={22} ml={0.5} color="#ddd" fontWeight="bold">Create Driver</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>

                <Typography sx={{ color: "gray", fontSize: 14, mb: 2, ml: 0.5 }}>Driver details</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <Controller
                            name="firstname"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField {...field} required label="First Name" fullWidth />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Controller
                            name="lastname"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField {...field} required label="Last Name" fullWidth />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} sm={2}>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField {...field} label="Nickname" fullWidth />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField {...field} required label="Phone" type="number" fullWidth />
                            )}
                        />
                    </Grid>
                </Grid>

                <Typography sx={{ color: "gray", fontSize: 14, mb: 0, ml: 0.5, mt: 3 }}>Account details</Typography>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} sm={2}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField {...field} required label="Email" type="email" fullWidth />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField {...field} required label="Password" type="password" fullWidth />
                            )}
                        />
                    </Grid>
                </Grid>

                <Typography sx={{ color: "gray", fontSize: 14, mb: 0, ml: 0.5, mt: 3 }}>Assign to vehicle</Typography>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12} sm={2}>
                        <Box>
                            <Autocomplete
                                disablePortal
                                options={vehicles?.map((item) => ({
                                    label: item.nickname,
                                    id: item.id,
                                })) || []}
                                onChange={(event, value: any) => {
                                    setVehicleFilter(value.id)
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Assign to" />}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={1}>
                        <Button type="submit" variant="contained" color="primary" size="large" fullWidth sx={{ borderRadius: 15, backgroundColor: "#ef4e55" }}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </Box >
    );

};

export default CreateDriver;