import * as React from 'react';
import { FC } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

const autocompleteService = { current: null };
const placesService: any = { current: null };
const geocoder: any = { current: null };

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
    place_id?: string;
}

interface Props {
    title: string;
    setLatLng: any;
    changedLatitude: any;
    changedLongitude: any;
    iValue: any;
}

const GoogleMapAutocomplete: FC<Props> = ({ title, setLatLng, changedLatitude, changedLongitude, iValue }) => {

    const [value, setValue] = React.useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

    const fetch = React.useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void,
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback,
                    );
                },
                400,
            ),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService();
        }

        if (!placesService.current && (window as any).google) {
            placesService.current = new (
                window as any
            ).google.maps.places.PlacesService(document.createElement('div'));
        }

        if (!geocoder.current && (window as any).google) {
            geocoder.current = new (window as any).google.maps.Geocoder();
        }

        if (!autocompleteService.current) return undefined;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const handlePlaceSelect = (placeId: string) => {
        if (placesService.current) {
            placesService.current.getDetails({ placeId }, (place: any, status: any) => {
                if (status === (window as any).google.maps.places.PlacesServiceStatus.OK) {
                    const latitude = place.geometry.location.lat();
                    const longitude = place.geometry.location.lng();
                    setLatLng({ lat: latitude, lng: longitude });
                    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
                }
            });
        }
    };

    React.useEffect(() => {
        if (geocoder.current && changedLatitude && changedLongitude) {
            const latLng = { lat: changedLatitude, lng: changedLongitude };
            geocoder.current.geocode({ location: latLng }, (results: any, status: any) => {
                if (status === (window as any).google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        const formattedAddress = results[0].formatted_address;
                        setValue(formattedAddress);
                    }
                } else {
                    console.error('Geocoder failed due to: ' + status);
                }
            });
        }
    }, [changedLatitude, changedLongitude, value]);

    React.useEffect(() => {
        if (inputValue) {
            iValue(inputValue);
        }
    }, [inputValue])

    return (
        <Autocomplete
            sx={{ width: 400 }}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No locations"
            onChange={(event: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

                if (newValue && newValue.place_id) {
                    handlePlaceSelect(newValue.place_id);
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={title} fullWidth />
            )}
            renderOption={(props: any, option: any) => {
                const { key, ...optionProps } = props;
                const matches =
                    option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                );
                return (
                    <li key={key} {...optionProps}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}

export default GoogleMapAutocomplete;