import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { Check, Sync, Block, Cancel, Assignment, CheckCircle } from '@mui/icons-material';
import { useGetOne } from 'react-admin';

interface Props {
    date: any;
    setStatus: any;
}

const OrderStatus: React.FC<Props> = ({ date, setStatus }) => {

    const [statusMenu, setStatusMenu] = React.useState([]);

    const { data, isLoading, error, refetch } = useGetOne(
        `taxi-orders/total-by-status?date=${date ? date : new Date()}`,
        { id: "" }
    );

    const menuItems = [
        { label: 'New Orders', status: 'NEW_ORDER', icon: <Check /> },
        { label: 'In Process', status: 'IN_PROGRESS', icon: <Sync /> },
        { label: 'Assigned', status: 'ASSIGNED', icon: <Assignment /> },
        { label: 'Accepted', status: 'ACCEPTED', icon: <CheckCircle /> },
        { label: 'Rejected', status: 'REJECTED', icon: <Block /> },
        { label: 'Cancelled', status: 'CANCELLED', icon: <Cancel /> },
        { label: 'Completed', status: 'COMPLETED', icon: <Check /> },
    ];

    React.useEffect(() => {

        const intervalId = setInterval(() => {
            refetch();
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };

    }, [refetch])

    React.useEffect(() => {

        if (data) {

            let statusMenuTemp: any = [];

            for (let index = 0; index < menuItems.length; index++) {

                const element: any = { ...menuItems[index] };
                let found = false;

                Object.keys(data).forEach(key => {
                    if (element.status === data[key].status) {
                        element.total = data[key].total;
                        found = true;
                    }
                });

                if (!found) {
                    element.total = 0;
                }

                statusMenuTemp.push(element);
            }

            setStatusMenu(statusMenuTemp);

        }

    }, [data]);

    return (
        <Box>
            <Box sx={{ display: "flex" }}>
                {statusMenu.map((item: any, index) => (
                    <Box sx={{ mt: 2, ml: index === 0 ? 0 : 2 }} key={item.label}>
                        <Button
                            onClick={() => {
                                setStatus(item.status);
                                localStorage.setItem("status", item.status);
                            }}
                            variant="contained"
                            size="large"
                            startIcon={item.icon}
                            sx={{
                                background: localStorage.getItem("status") == item.status ? "#07a619" : "linear-gradient(145deg, #222, #333)",
                                pl: 3,
                                pr: 3,
                                borderRadius: 3,
                                '&:hover': {
                                    backgroundColor: "#222",
                                    color: "#ddd"
                                }
                            }}
                        >
                            {item.label}
                            <Typography
                                component="span"
                                sx={{
                                    color: '#ffcc00',
                                    fontWeight: 'bold',
                                    ml: 1
                                }}
                            >
                                ({item.total})
                            </Typography>
                        </Button>
                    </Box>
                ))}
                <Box sx={{ mt: 2.2, ml: 2 }}>
                    <Button
                        variant="contained"
                        href="/#/reservation-orders"
                        size="large"
                        sx={{
                            background: "linear-gradient(145deg, #222, #333)",
                            pl: 3,
                            pr: 3,
                            borderRadius: 3,
                            '&:hover': {
                                backgroundColor: "#222",
                                color: "#ddd"
                            }
                        }}
                    >
                        Reservations
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default OrderStatus;
