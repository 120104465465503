import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useCreate, useGetOne } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useMediaQuery, Theme } from '@mui/material';
import jwt_decode from "jwt-decode";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CircularProgress, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch sx={{ mr: 2 }} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#9e3c11' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const Create: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const [create, { isLoading, error, data }] = useCreate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const [dateValue, setDateValue] = React.useState<Dayjs | null>(
        dayjs(new Date())
    );

    const { data: settingsVehicleSelected, isLoading: isLoadingSettingsVehicleSelected, error: errorSettingsVehicleSelected } = useGetOne(
        'settings-vehicle-selected/selected',
        {
            id: ""
        }
    );

    const [checkedOtherOptions, setCheckedOtherOptions] = React.useState(false);

    const [missedPreviousRefuelling, setMissedPreviousRefuelling] = React.useState(false);

    const [changeFillingTanksOptions, setChangeFillingTanksOptions] = React.useState("primary");

    const [areYouFillingTankPrimary, setAreYouFillingTankPrimary] = React.useState(false);
    const [areYouFillingTankSecondary, setAreYouFillingTankSecondary] = React.useState(false);

    const [pricePrimary, setPricePrimary] = useState(0);
    const [totalPrimary, setTotalPrimary] = useState(0);
    const [unitValuePrimary, setUnitValuePrimary] = useState(0);

    const [priceSecondary, setPriceSecondary] = useState(0);
    const [totalSecondary, setTotalSecondary] = useState(0);
    const [unitValueSecondary, setUnitValueSecondary] = useState(0);

    const onChangedPrimaryPrice = (event: any) => {
        setPricePrimary(event.target.value);
        setValue("total_cost_primary", (parseFloat(event.target.value) * unitValuePrimary).toFixed(2));
    }

    const onChangedPrimaryTotal = (event: any) => {
        setTotalPrimary(event.target.value);
        setValue("unit_value_primary", (parseFloat(event.target.value) / pricePrimary).toFixed(2));
    }

    const onChangedPrimaryUnitValue = (event: any) => {
        setUnitValuePrimary(event.target.value);
        setValue("total_cost_primary", (parseFloat(event.target.value) * pricePrimary).toFixed(2));
    }

    const onChangedSecondPrice = (event: any) => {
        setPriceSecondary(event.target.value);
        setValue("total_cost_secondary", (parseFloat(event.target.value) * unitValueSecondary).toFixed(2));
    }

    const onChangedSecondTotal = (event: any) => {
        setTotalSecondary(event.target.value);
        setValue("unit_value_secondary", (parseFloat(event.target.value) / priceSecondary).toFixed(2));
    }

    const onChangedSecondUnitValue = (event: any) => {
        setUnitValueSecondary(event.target.value);
        setValue("total_cost_secondary", (parseFloat(event.target.value) * priceSecondary).toFixed(2));
    }

    const [disablePriceValuePriamry, setDisablePriceValuePrimary] = useState(false);
    const [freeChargeValuePrimary, setFreeChargeValuePrimary] = useState(false);

    const onChangedFreeChargeElectricPrimary = (event: any) => {
        if (event.target.checked) {
            setDisablePriceValuePrimary(event.target.checked);
            setValue("price_primary", 0);
            setValue("total_cost_primary", 0);
            setDisablePriceValuePrimary(true);
        } else {
            setValue("price_primary", 0);
            setValue("total_cost_primary", 0);
            setDisablePriceValuePrimary(false);
        }
    }

    const [disablePriceValueSecondary, setDisablePriceValueSecondary] = useState(false);
    const [freeChargeValueSecondary, setFreeChargeValueSecondary] = useState(false);

    const onChangedFreeChargeElectricSecondary = (event: any) => {
        if (event.target.checked) {
            setFreeChargeValueSecondary(event.target.checked);
            setValue("price_secondary", 0);
            setValue("total_cost_secondary", 0);
            setDisablePriceValueSecondary(true);
        } else {
            setValue("price_secondary", 0);
            setValue("total_cost_secondary", 0);
            setDisablePriceValueSecondary(false);
        }
    }

    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        let tanks: any = [];

        if (settingsVehicleSelected?.vehicle.two_tanks) {

            if (changeFillingTanksOptions === "primary") {
                tanks.push(
                    {
                        fuel_id: settingsVehicleSelected?.vehicle.vehicle_tanks[0].fuel_id,
                        unit_value: Number(data.unit_value_primary),
                        price: Number(data.price_primary),
                        total_cost: Number(data.total_cost_primary),
                        are_you_filling_tank: areYouFillingTankPrimary,
                        type: "PRIMARY"
                    }
                )
            } else if (changeFillingTanksOptions === "secondary") {
                tanks.push(
                    {
                        fuel_id: settingsVehicleSelected?.vehicle.vehicle_tanks[1].fuel_id,
                        unit_value: Number(data.unit_value_secondary),
                        price: Number(data.price_secondary),
                        total_cost: Number(data.total_cost_secondary),
                        are_you_filling_tank: areYouFillingTankSecondary,
                        type: "SECONDARY"
                    }
                )
            } else if (changeFillingTanksOptions === "both") {
                tanks.push(
                    {
                        fuel_id: settingsVehicleSelected?.vehicle.vehicle_tanks[0].fuel_id,
                        unit_value: Number(data.unit_value_primary),
                        price: Number(data.price_primary),
                        total_cost: Number(data.total_cost_primary),
                        are_you_filling_tank: areYouFillingTankPrimary,
                        type: "PRIMARY"
                    },
                    {
                        fuel_id: settingsVehicleSelected?.vehicle.vehicle_tanks[1].fuel_id,
                        unit_value: Number(data.unit_value_secondary),
                        price: Number(data.price_secondary),
                        total_cost: Number(data.total_cost_secondary),
                        are_you_filling_tank: areYouFillingTankSecondary,
                        type: "SECONDARY"
                    }
                )
            }

        } else {
            tanks.push(
                {
                    fuel_id: settingsVehicleSelected?.vehicle.vehicle_tanks[0].fuel_id,
                    unit_value: Number(data.unit_value_primary),
                    price: Number(data.price_primary),
                    total_cost: Number(data.total_cost_primary),
                    are_you_filling_tank: areYouFillingTankPrimary,
                    type: "PRIMARY"
                }
            )
        }

        let sendData = {
            data: {
                vehicle_id: settingsVehicleSelected?.vehicle.id,
                date: dateValue,
                odometer: Number(data.odometer),
                missed_previous_refueling: missedPreviousRefuelling,
                two_tanks: settingsVehicleSelected?.vehicle.two_tanks,
                notes: data.notes === undefined ? "" : data.notes,
            },
            tanks: tanks
        };

        if (tanks.length > 0) {
            create('refueling', { data: sendData });
        } else {
            notify("Please contact with your administrator", { type: "info" });
        }

    }

    if (data) {
        notify("Refueling added successfully", { type: "success" });
        navigate("/refueling");
        refresh();
    }

    if (error) {
        notify("Refueling was not added successfully", { type: "error" });
    }

    useEffect(() => {

        if (settingsVehicleSelected?.two_tanks) {

            if (changeFillingTanksOptions === "primary") {

                setPricePrimary(settingsVehicleSelected?.settings["last_price_per_unit_primary"]);
                setValue("price_primary", settingsVehicleSelected?.settings["last_price_per_unit_primary"]);

            } else if (changeFillingTanksOptions === "secondary") {

                setPriceSecondary(settingsVehicleSelected?.settings["last_price_per_unit_secondary"]);
                setValue("price_secondary", settingsVehicleSelected?.settings["last_price_per_unit_secondary"]);

            } else if (changeFillingTanksOptions === "both") {

                setPricePrimary(settingsVehicleSelected?.settings["last_price_per_unit_primary"]);
                setValue("price_primary", settingsVehicleSelected?.settings["last_price_per_unit_primary"]);

                setPriceSecondary(settingsVehicleSelected?.settings["last_price_per_unit_secondary"]);
                setValue("price_secondary", settingsVehicleSelected?.settings["last_price_per_unit_secondary"]);

            }

        } else {

            setPricePrimary(settingsVehicleSelected?.settings["last_price_per_unit_primary"]);
            setValue("price_primary", settingsVehicleSelected?.settings["last_price_per_unit_primary"]);

        }

    }, [settingsVehicleSelected, changeFillingTanksOptions])

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd", ml: 1 }}>Add Refueling</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 5 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={5}>
                            <MobileDatePicker
                                label="Date *"
                                inputFormat="DD/MM/YYYY"
                                value={dateValue}
                                onChange={(newValue: any) => {
                                    setDateValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item md={2} xs={4}>
                            <TextField
                                autoComplete="off"
                                id="odometer"
                                type="number"
                                label="Odometer *"
                                placeholder="Odometer"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                {...register("odometer", { required: true })}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                <Grid item md={3} xs={6}>
                    <Typography sx={{ ml: 1, fontSize: 12, mt: 1, color: "gray" }}>Last odometer: {settingsVehicleSelected?.vehicle.last_odometer === null ? 0 : settingsVehicleSelected?.vehicle.last_odometer} km</Typography>
                </Grid>
                <Box sx={{ m: 1, mt: 5, ml: -0.01 }}>
                    {settingsVehicleSelected && settingsVehicleSelected?.vehicle.two_tanks &&
                        <FormControl sx={{ ml: 0.6 }}>
                            <FormLabel id="filling-tanks-label">Filling Tanks</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="filling-tanks-label"
                                name="row-radio-buttons-group"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setChangeFillingTanksOptions((event.target as HTMLInputElement).value)}
                                defaultValue={changeFillingTanksOptions}
                            >
                                <FormControlLabel value="primary" control={<Radio />} label="Primary" />
                                <FormControlLabel value="secondary" control={<Radio />} label="Secondary" />
                                <FormControlLabel value="both" control={<Radio />} label="Both Tanks" />
                            </RadioGroup>
                        </FormControl>
                    }
                    <Box sx={{ m: 3 }}></Box>
                    <Box>
                        {(changeFillingTanksOptions === "primary" || changeFillingTanksOptions === "both") &&
                            <Box>
                                {settingsVehicleSelected && settingsVehicleSelected?.vehicle.two_tanks && <Typography sx={{ mb: 2, ml: 0.5, color: "gray" }}>Primary Tank</Typography>}
                                {settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[0].fuel.name === "Electric" &&
                                    <>
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    control={<IOSSwitch onChange={(e: any) => onChangedFreeChargeElectricPrimary(e)} sx={{ ml: 2 }} name="free_charging" />}
                                                    label="Free charging"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <Box sx={{ m: 3 }}></Box>
                                    </>
                                }
                                <Grid container spacing={2}>
                                    <Grid item md={2} xs={5}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            id="price_primary"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: "1"
                                            }}
                                            label={`${settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[0].fuel.name === "Electric" ? "Price / Kwh" : "Price / Litres"} *`}
                                            placeholder={`${settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[0].fuel.name === "Electric" ? "Price / Kwh" : "Price / Litres"} *`}
                                            disabled={disablePriceValuePriamry}
                                            {...register("price_primary", { required: true })}
                                            onChange={(e: any) => onChangedPrimaryPrice(e)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={3}>
                                        <TextField
                                            autoComplete="off"
                                            id="unit_value_primary"
                                            type="number"
                                            {...register("unit_value_primary", { required: true })}
                                            onChange={(e: any) => onChangedPrimaryUnitValue(e)}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: "1"
                                            }}
                                            label={`${settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[0].fuel.name === "Electric" ? "Kwh" : "Litres"} *`}
                                            placeholder={`${settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[0].fuel.name === "Electric" ? "Kwh" : "Litres"} *`}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            id="total_cost_primary"
                                            label="Total Cost *"
                                            placeholder="Total Cost"
                                            {...register("total_cost_primary")}
                                            onChange={(e: any) => onChangedPrimaryTotal(e)}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: "1"
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ m: 3 }} />
                                <Grid container spacing={2}>
                                    <Grid item md={3} xs={12}>
                                        <FormControlLabel
                                            control={<IOSSwitch onChange={(e: any) => setAreYouFillingTankPrimary(e.target.checked)} sx={{ ml: 2 }} name="are_you_filling_tank_primary" />}
                                            label="Are you filling the tank ?"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Box sx={{ m: 3 }}></Box>
                        {(changeFillingTanksOptions === "secondary" || changeFillingTanksOptions === "both") &&
                            <Box>
                                <Typography sx={{ mb: 2, ml: 0.5, color: "gray" }}>Secondary Tank</Typography>
                                {settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[1].fuel.name === "Electric" &&
                                    <>
                                        <FormControl component="fieldset">
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    control={<IOSSwitch onChange={(e: any) => onChangedFreeChargeElectricSecondary(e)} sx={{ ml: 2 }} name="free_charging" />}
                                                    label="Free charging"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <Box sx={{ m: 3 }}></Box>
                                    </>
                                }
                                <Grid container spacing={2}>
                                    <Grid item md={2} xs={4}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            id="price_secondary"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: "any"
                                            }}
                                            label={`${settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[1].fuel.name === "Electric" ? "Price / Kwh" : "Price / L"} *`}
                                            placeholder={`${settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[1].fuel.name === "Electric" ? "Price / Kwh" : "Price / L"} *`}
                                            disabled={disablePriceValueSecondary}
                                            {...register("price_secondary", { required: true })}
                                            onChange={(e: any) => onChangedSecondPrice(e)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            id="unit_value_secondary"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: "any"
                                            }}
                                            label={`${settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[1].fuel.name === "Electric" ? "Kwh" : "Litres"} *`}
                                            placeholder={`${settingsVehicleSelected && settingsVehicleSelected?.vehicle.vehicle_tanks[1].fuel.name === "Electric" ? "Kwh" : "Litres"} *`}
                                            {...register("unit_value_secondary", { required: true })}
                                            onChange={(e: any) => onChangedSecondUnitValue(e)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <TextField
                                            autoComplete="off"
                                            type="number"
                                            id="total_cost_secondary"
                                            label="Total Cost *"
                                            {...register("total_cost_secondary", { required: true })}
                                            onChange={(e: any) => onChangedSecondTotal(e)}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: "any"
                                            }}
                                            placeholder="Total Cost"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ m: 3 }} />
                                <Grid container spacing={2}>
                                    <Grid item md={3} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<IOSSwitch onChange={(e: any) => setAreYouFillingTankSecondary(e.target.checked)} sx={{ ml: 2 }} name="are_you_filling_tank_secondary" />}
                                                label="Are you filling the tank ?"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box sx={{ m: 4 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={20}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={15}>
                                    <Checkbox
                                        checked={checkedOtherOptions}
                                        edge="start"
                                        sx={{ display: "none" }}
                                    />
                                    <Button
                                        startIcon={checkedOtherOptions ? <RemoveIcon /> : <AddIcon />}
                                        onClick={() => { setCheckedOtherOptions((check: any) => !check) }}
                                        sx={{
                                            color: "gray",
                                            fontSize: 15,
                                            pr: 2
                                        }}
                                    >
                                        More Options
                                    </Button>
                                </Grid>
                            </Grid>
                            {checkedOtherOptions &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} xs={12}>
                                            <FormGroup sx={{ mt: 4 }}>
                                                <FormControlLabel
                                                    control={<IOSSwitch onChange={(e: any) => setMissedPreviousRefuelling(e.target.checked)} sx={{ ml: 2 }} name="missed_previous_refueling" />}
                                                    label="Missed Previous refueling  ?"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ m: 4 }} />
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <TextField
                                                autoComplete="off"
                                                id="notes"
                                                label="Notes"
                                                placeholder="Notes (Optional)"
                                                {...register("notes", { required: false })}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/refueling")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

}

export default Create;