import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useCreate } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { CircularProgress, useMediaQuery, Theme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// imports components
import SelectableFilter from '../../components/inputs/SelectableFilter';

const Create: FC = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const [create, { isLoading, error, data }]: any = useCreate();

    const [selectedVehicleType, setSelectedVehicleType] = useState<any>("car");

    const onChangeVehicleType = (event: any) => {
        setSelectedVehicleType(event.target.defaultValue);
    }

    const [selectedVehicleBrand, setSelectedVehicleBrand] = useState<any>(0);

    const selectedVehicleBrandItem = (data: any) => {
        setSelectedVehicleBrand(data.id);
    }

    const [primaryFuelCapacity, setPrimaryFuelCapacity] = useState<any>(0);
    const [primaryTank, setSelectedPrimaryTank] = useState<any>(0);

    const selectedPrimaryTank = (data: any) => {
        setSelectedPrimaryTank(data.id);
    }

    const [twoTankChecked, setTwoTankChecked] = useState(false);

    const [secondaryFuelCapacity, setSecondaryFuelCapacity] = useState<any>(0);
    const [secondaryTank, setSelectedSecondaryTank] = useState<any>(0);

    const selectedSecondaryTank = (data: any) => {
        setSelectedSecondaryTank(data.id);
    }

    const [selectedKmType, setSelectedKmType] = useState<any>("km");

    const onChangeKmType = (event: any) => {
        setSelectedKmType(event.target.defaultValue);
    }

    const [checkedOtherOptions, setCheckedOtherOptions] = React.useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        let tanks: any = []

        if (!twoTankChecked) {
            tanks.push(
                {
                    fuel_id: primaryTank,
                    unit_capacity: parseFloat(primaryFuelCapacity),
                    type: "PRIMARY"
                }
            )
        } else {
            tanks.push(
                {
                    fuel_id: primaryTank,
                    unit_capacity: parseFloat(primaryFuelCapacity),
                    type: "PRIMARY"
                },
                {
                    fuel_id: secondaryTank,
                    unit_capacity: parseFloat(secondaryFuelCapacity),
                    type: "SECONDARY"
                }
            )
        }

        let sendData = {
            data: {
                type: selectedVehicleType,
                license_plate: data.license_plate,
                make_id: selectedVehicleBrand,
                make_model: data.make_model,
                make_year: data.make_year,
                km_type: selectedKmType,
                two_tanks: twoTankChecked,
                nickname: data.nickname,
                notes: data.notes
            },
            tanks: tanks
        };

        if (selectedVehicleBrand === 0) {
            notify("Please select vehicle brand.", { type: "info" });
            return;
        }

        if (primaryTank === 0) {
            notify("Please select primary tank.", { type: "info" });
            return;
        }

        if (twoTankChecked) {
            if (secondaryTank === 0) {
                notify("Please select secondary tank.", { type: "info" });
                return;
            }
        }

        create('vehicles', { data: sendData });

    }

    if (data) {
        notify("Vehicle added successfully", { type: "success" });
        navigate("/vehicles");
        refresh();
    }

    if (error) {
        notify(error.message, { type: "error" });
    }

    return (
        <Box sx={{ mt: isSmall ? 0 : 7, p: 4, background: "linear-gradient(145deg, #222, #333)", borderRadius: 10, ml: 4, mr: 4, mb: 5 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd", mb: 2 }}>Add Vehicle</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 3 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                    <RadioGroup
                        row
                        onChange={(e) => onChangeVehicleType(e)}
                        defaultValue="car"
                        aria-labelledby="type"
                        name="type"
                    >
                        <FormControlLabel value="car" control={<Radio />} label="Car" />
                        <FormControlLabel value="truck" control={<Radio />} label="Truck" />
                        <FormControlLabel value="bus" control={<Radio />} label="Bus" />
                        <FormControlLabel value="motorcycle" control={<Radio />} label="Motorcycle" />
                    </RadioGroup>
                </FormControl>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={6}>
                        <TextField
                            autoComplete="off"
                            id="license_plate"
                            label="License Plate *"
                            placeholder="License Plate"
                            {...register("license_plate", { required: true })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={3}>
                        <SelectableFilter resource="makes" title="Vehicle Brand" source="name" selected={selectedVehicleBrandItem} />
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={3}>
                        <TextField
                            autoComplete="off"
                            id="make_model"
                            label="Vehicle Model *"
                            placeholder="Vehicle Model"
                            {...register("make_model", { required: true })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2} xs={3}>
                        <TextField
                            autoComplete="off"
                            type="number"
                            id="make_year"
                            label="Vehicle Year *"
                            placeholder="Vehicle Year"
                            {...register("make_year", { required: true })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={4}>
                        <SelectableFilter resource="fuel" title="Primary Tank" source="name" selected={selectedPrimaryTank} />
                    </Grid>
                    <Grid item md={2} xs={4}>
                        <TextField
                            autoComplete="off"
                            type="number"
                            id="primary_fuel_capacity"
                            InputLabelProps={{
                                shrink: true
                            }}
                            label={`${primaryTank && primaryTank === 3 ? "Fuel Capacity (kwh)" : "Fuel Capacity (L)"} *`}
                            placeholder={`${primaryTank && primaryTank === 3 ? "Fuel Capacity (kwh)" : "Fuel Capacity (L)"} *`}
                            defaultValue={primaryFuelCapacity}
                            onChange={(e: any) => setPrimaryFuelCapacity(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={20}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={15}>
                                    <Checkbox
                                        edge="start"
                                        checked={twoTankChecked}
                                        sx={{ display: "none" }}
                                    />
                                    <Button
                                        startIcon={twoTankChecked ? <RemoveIcon /> : <AddIcon />}
                                        onClick={() => { setTwoTankChecked((check: any) => !check) }}
                                        sx={{
                                            fontSize: 15
                                        }}
                                    >
                                        Secondary Tank
                                    </Button>
                                </Grid>
                            </Grid>
                            {twoTankChecked &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item md={2} xs={4}>
                                            <SelectableFilter resource="fuel" title="Secondary Tank" source="name" selected={selectedSecondaryTank} />
                                        </Grid>
                                        <Grid item md={2} xs={4}>
                                            <TextField
                                                autoComplete="off"
                                                type="number"
                                                id="secondary_fuel_capacity"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                label={`${secondaryTank && secondaryTank.name == "Electric" ? "Fuel Capacity (kwh)" : "Fuel Capacity (L)"} *`}
                                                placeholder={`${secondaryTank && secondaryTank.name == "Electric" ? "Fuel Capacity (kwh)" : "Fuel Capacity (L)"} *`}
                                                defaultValue={secondaryFuelCapacity}
                                                onChange={(e: any) => setSecondaryFuelCapacity(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={5} xs={8}>
                        <FormControl>
                            <RadioGroup
                                row
                                onChange={(e) => onChangeKmType(e)}
                                defaultValue="km"
                                aria-labelledby="type"
                                name="km_type"
                            >
                                <FormControlLabel value="km" control={<Radio />} label="Kilometer (Km)" />
                                <FormControlLabel value="mile" control={<Radio />} label="Mile (Mil)" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={20}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={15}>
                                    <Checkbox
                                        checked={checkedOtherOptions}
                                        edge="start"
                                        sx={{ display: "none" }}
                                    />
                                    <Button
                                        startIcon={checkedOtherOptions ? <RemoveIcon /> : <AddIcon />}
                                        onClick={() => { setCheckedOtherOptions((check: any) => !check) }}
                                        sx={{
                                            color: "gray",
                                            fontSize: 15,
                                            pr: 2
                                        }}
                                    >
                                        More Options
                                    </Button>
                                </Grid>
                            </Grid>
                            {checkedOtherOptions &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} xs={9}>
                                            <TextField
                                                sx={{ mt: 4 }}
                                                autoComplete="off"
                                                id="nickname"
                                                label="Nickname"
                                                placeholder="Nickname (Optional)"
                                                {...register("nickname", { required: false })}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ m: 3 }} />
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12}>
                                            <TextField
                                                autoComplete="off"
                                                id="notes"
                                                label="Notes"
                                                placeholder="Notes (Optional)"
                                                {...register("notes", { required: false })}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }}></Box>
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/vehicles")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Create;