import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography } from '@mui/material';
import { useLogout, usePermissions } from 'react-admin';
import Person2Icon from '@mui/icons-material/Person2';
import { useGetOne } from 'react-admin';
import useVehicleStatus from '../../../services/vehicles-status';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import { Marker, AdvancedMarker, Map, MapCameraChangedEvent, useMap } from '@vis.gl/react-google-maps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MarkerStatus = ({ item, showtext }: any) => {

    const { data, isLoading, error } = useVehicleStatus(item?.gpsData.id);

    const variables = React.useMemo(() => {

        const vars: Record<string, any> = {};

        data?.variables?.forEach((variable: any) => {
            if (variable?.name) {
                vars[variable.name] = variable?.value || "False";
            }
        });

        return vars;

    }, [data]);

    return (
        <Box>
            {variables &&
                <AdvancedMarker
                    position={{
                        lat: item.gpsData.trackPoint.position.latitude,
                        lng: item.gpsData.trackPoint.position.longitude,
                    }}
                >
                    <Box
                        sx={{
                            background: variables["Ignition"] == "True" && variables["Pasagjer"] == "True" ? "#ff4d4d" : "#00ff00",
                            // borderColor: markerData?.id === item.id ? "#07a619" : "linear-gradient(145deg, #222, #333)",
                            height: 20,
                            borderRadius: 1
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
                                    <Typography
                                        sx={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}
                                        fontSize={12}
                                        color={variables["Ignition"] == "True" && variables["Pasagjer"] == "True" ? "#ddd" : "#222"}
                                        fontWeight="bold"
                                    >
                                        {item.nickname || ""}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: -2.5
                        }}
                    >
                        <ArrowDropDownIcon sx={{ color: variables["Ignition"] == "True" && variables["Pasagjer"] == "True" ? "#ff4d4d" : "#00ff00", fontSize: 40 }} />
                    </Box>
                </AdvancedMarker>
            }
        </Box>
    );

};

export default MarkerStatus;