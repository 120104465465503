import { useQuery } from 'react-query';
import { useDataProvider } from 'react-admin';

const useVehicles = () => {

    const dataProvider = useDataProvider();

    return useQuery(
        ['vehicles'],
        () =>
            dataProvider.getList('gps-track/track-vehicles', {
                pagination: { page: 1, perPage: 23 },
                sort: { field: 'date', order: 'DESC' },
                filter: {}
            }).then(({ data, total }) => ({ data, total })),
        {
            refetchInterval: 10000,
            refetchIntervalInBackground: true,
            keepPreviousData: true,
        }
    );

};

export default useVehicles;