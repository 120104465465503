import * as React from 'react';
import { Box, Typography, Button, Stack, Avatar } from '@mui/material';
import {
    BrowserRouter as Router,
    Route,
    useLocation,
    useSearchParams
} from "react-router-dom";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InsightsIcon from '@mui/icons-material/Insights';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import HelpIcon from '@mui/icons-material/Help';
import AssistantIcon from '@mui/icons-material/Assistant';
import PaidIcon from '@mui/icons-material/Paid';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RouteIcon from '@mui/icons-material/Route';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { CarRental } from '@mui/icons-material';
import logo from "../../assets/images/green-logo.png"

const CompanyMenu = () => {

    const location = useLocation();

    const taxidispatch = [
        {
            id: 1,
            title: "Green Taxi",
            items: [
                {
                    id: 1,
                    title: "Track",
                    path: "/",
                    icon: <AnalyticsIcon />
                },
                {
                    id: 2,
                    title: "Orders",
                    path: "/taxi-orders",
                    icon: <AnalyticsIcon />
                },
                {
                    id: 3,
                    title: "Drivers",
                    path: "/taxi-drivers",
                    icon: <AnalyticsIcon />
                }
            ]
        },
        {
            id: 2,
            title: "Fleet",
            items: [
                {
                    id: 1,
                    title: "Vehicles",
                    path: "/vehicles",
                    icon: <CarRental />
                },
                {
                    id: 2,
                    title: "Refueling",
                    path: "/refueling",
                    icon: <LocalGasStationIcon />
                },
                {
                    id: 3,
                    title: "Expenses",
                    path: "/expenses",
                    icon: <AccountBalanceWalletIcon />
                },
                {
                    id: 4,
                    title: "Services",
                    path: "/service",
                    icon: <MinorCrashIcon />
                },
                {
                    id: 5,
                    title: "Incomes",
                    path: "/incomings",
                    icon: <PaidIcon />
                },
                {
                    id: 6,
                    title: "Reminders",
                    path: "/reminders",
                    icon: <NotificationAddIcon />
                }
            ]
        }
    ]

    return (
        <Box>
            <Box sx={{ textAlign: "center", mt: -8 }}>
                <img src={logo} width={80} />
            </Box>
            {taxidispatch.map((item) => (
                <Box key={item.id}>
                    <Typography
                        sx={{
                            color: "#7a7a7a",
                            fontSize: 12,
                            fontWeight: "bold",
                            mt: 2,
                            ml: 1,
                            mb: 2,
                        }}
                    >
                        {item.title}
                    </Typography>
                    <Box sx={{}}>
                        {item.items.map((record: any, index) => (
                            <Box key={record.id} sx={{ flexDirection: "column" }}>
                                <Button
                                    variant="text"
                                    href={`/#${record.path}`}
                                    sx={{
                                        color: location.pathname == record.path ? "#ffffff" : "#9e9e9e",
                                        background: location.pathname == record.path
                                            ? "#07a619" // Gradient për butonat aktivë
                                            : "linear-gradient(145deg, #222, #333)", // Gradient për butonat inaktivë
                                        boxShadow: location.pathname == record.path
                                            ? "#07a619" // Glow për elementët aktivë
                                            : "none",
                                        width: 80,
                                        height: 80,
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: 12,
                                        textTransform: "capitalize",
                                        transition: "background 0.3s, transform 0.2s, box-shadow 0.3s", // Efekt animimi
                                        "&:hover": {
                                            background: "linear-gradient(145deg, #444, #555)",
                                            transform: "scale(1.08)", // Rritet pak kur je në hover
                                        },
                                        "& .MuiButton-startIcon": {
                                            transition: "transform 0.2s", // Animacion për ikonën
                                        },
                                        "&:hover .MuiButton-startIcon": {
                                            transform: "translateX(5px)", // Ikona lëviz pak në hover
                                        },
                                    }}
                                >
                                    <AnalyticsIcon sx={{ fontSize: 24, mb: 0.5 }} />
                                    {record.title}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    )

}

export default CompanyMenu;