import * as React from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Typography, TextField, Chip, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, List, ListItem, ListItemText, ListItemButton, Autocomplete } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetList, useUpdate, useNotify, useRefresh } from 'react-admin';
import OrderStatus from './OrderStatus';
import NewOrders from './NewOrders';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InProccessOrders from './InProccessOrders';
import AssignedOrders from './AssignedOrders';
import AcceptedOrders from './AcceptedOrders';
import RejectedOrders from './RejectedOrders';
import CancelledOrders from './CancelledOrder';
import CompletedOrders from './CompletedOrders';

const TaxiOrders = () => {

    const [dateValue, setDateValue] = React.useState(new Date());
    const [vehicleFilter, setVehicleFilter] = React.useState(0);
    const [status, setStatus] = React.useState<any>("NEW_ORDER");

    const changeStatus = (status: string) => {
        setStatus(status);
    }

    const { data: vehicles, total, isLoading: isLoadingVehicles, error: errorVehicles, refetch } = useGetList(
        'vehicles',
        {
            pagination: { page: 1, perPage: 23 },
            sort: { field: 'date', order: 'DESC' },
            filter: {}
        }
    );

    React.useEffect(() => {

        if (localStorage.getItem("status")) {
            setStatus(localStorage.getItem("status"))
        } else {
            setStatus(localStorage.setItem("status", "NEW_ORDER"))
            setStatus("NEW_ORDER")
        }

    }, [status])

    return (
        <Box sx={{ mt: 6, p: 4, background: "linear-gradient(145deg, #222, #333)", borderRadius: 5, ml: 4, mr: 4, mb: 5 }}>
            <Typography fontSize={22} ml={1} color="#ddd" fontWeight="bold">Taxi Orders</Typography>
            <Box sx={{ pt: 2, display: "flex" }}>
                <Box sx={{ width: 300, mr: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Orders Date"
                            value={dateValue}
                            onChange={(newValue: any) => setDateValue(newValue)}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box>
                    <Autocomplete
                        disablePortal
                        options={vehicles?.map((item) => ({
                            label: item.nickname,
                            id: item.id,
                        })) || []}
                        onChange={(event, value: any) => {
                            setVehicleFilter(value.id)
                            console.log("Vlera e selektuar:", value);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Filter by vehicle" />}
                    />
                </Box>
            </Box>
            <OrderStatus date={dateValue} setStatus={changeStatus} />
            {status == "NEW_ORDER" &&
                <NewOrders date={dateValue} vehicle={vehicleFilter} />
            }
            {status == "IN_PROGRESS" &&
                <InProccessOrders date={dateValue} vehicle={vehicleFilter} />
            }
            {status == "ASSIGNED" &&
                <AssignedOrders date={dateValue} vehicle={vehicleFilter} />
            }
            {status == "ACCEPTED" &&
                <AcceptedOrders date={dateValue} vehicle={vehicleFilter} />
            }
            {status == "REJECTED" &&
                <RejectedOrders date={dateValue} vehicle={vehicleFilter} />
            }
            {status == "CANCELLED" &&
                <CancelledOrders date={dateValue} vehicle={vehicleFilter} />
            }
            {status == "COMPLETED" &&
                <CompletedOrders date={dateValue} vehicle={vehicleFilter} />
            }
        </Box>
    );
};

export default TaxiOrders;