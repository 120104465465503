import * as React from 'react';
import { Box, Typography, Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { useListContext, ListBase, Pagination, Loading, Error } from 'react-admin';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditIcon from '@mui/icons-material/Edit';
import OrderStatus from './OrderStatus';

interface Props {
    date: any;
    vehicle: any;
}

const RejectedOrders: React.FC<Props> = ({ date, vehicle }) => {
    return (
        <ListBase
            resource="taxi-orders"
            perPage={10}
            sort={{ field: 'date', order: 'DESC' }}
            filter={{ status: "REJECTED", date: format(new Date(date), "yyyy-MM-dd") }}
        >
            <OrderTable />
        </ListBase>
    );
};

const OrderTable = () => {

    const { data: orders, total, isLoading, error } = useListContext();

    return (
        <Box>
            <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper}>
                    <Table aria-label="Rejected Orders">
                        <TableHead>
                            <TableRow>
                                <TableCell>Client</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Destination(s)</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell>Assigned to</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {orders?.length > 0 ? (
                            <TableBody>
                                {orders.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.client_name || "No Name"}</TableCell>
                                        <TableCell>{row.client_phone || "No Number"}</TableCell>
                                        <TableCell>
                                            {row.pickup.length > 50
                                                ? row.pickup.substring(0, 50) + "..."
                                                : row.pickup}
                                            {" - "}
                                            {row.dropoff ? row.dropoff.length > 50
                                                ? row.dropoff.substring(0, 50) + "..."
                                                : row.dropoff : "-"}
                                        </TableCell>
                                        <TableCell>{row.rejection_reason || "No Reason"}</TableCell>
                                        <TableCell>
                                            <Chip label={row.driver?.name || "No Driver"} />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                startIcon={<EditIcon />}
                                                href={`/#/taxi-orders/edit/${row.id}`}
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <Box p={2}>
                                <Typography color="gray">No data available</Typography>
                            </Box>
                        )}
                    </Table>
                </TableContainer>
                {/* Pagination Control */}
                <Pagination />
            </Box>
        </Box>
    );
};

export default RejectedOrders;