import React, { FC } from 'react';
import { useGetOne } from 'react-admin';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';
import { useMediaQuery, Theme } from '@mui/material';
import jwt_decode from "jwt-decode";

// components
import CompanySettings from './components/CompanySettings';
import AccountSettings from './components/AccountSettings';
import Unit from './components/Unit';
import Formats from './components/Formats';
import Currency from './components/Currency';
import Language from './components/Language';
import ChangePassword from './components/ChangePassword';
import UsersSettings from './components/UsersSettings';

const Settings: FC = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    let decode: any = jwt_decode(`${localStorage.getItem("access_token")}`);

    let settings_id = decode['settings']['id'];
    let user_id = decode['user']['id'];

    const { data: settings, isLoading: isLoadingSettings, error: errorSettings } = useGetOne('settings', { id: settings_id });
    const { data: users, isLoading: isLoadingUsers, error: errorUsers } = useGetOne('users', { id: user_id });

    console.log(users);

    return (
        <Box sx={{ mt: 6, p: 4, background: "linear-gradient(145deg, #222, #333)", borderRadius: 5, ml: 4, mr: 4, mb: 5 }}>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: 30, color: "#ddd", mb: 2 }}>Settings</Typography>
                </Grid>
            </Grid>

            <Divider sx={{ mb: 5 }} />

            {settings && users &&
                <Stack divider={<Divider orientation="vertical" flexItem />} direction={isSmall ? "column" : "row"} spacing={2}>
                    {users?.user_role == "owner" &&
                        <Stack direction="row" spacing={2}>
                            <CompanySettings details={settings as any} />
                        </Stack>
                    }
                    {users?.user_role == "owner" &&
                        <Stack direction="row" spacing={2}>
                            <UsersSettings />
                        </Stack>
                    }
                    {(users?.user_role == "owner" || users?.user_role == "operator") &&
                        <Stack direction="row" spacing={2}>
                            <ChangePassword />
                        </Stack>
                    }
                    {users?.user_role == "owner" &&
                        <Stack direction="row" spacing={2}>
                            <Unit details={settings as any} />
                        </Stack>
                    }
                    {users?.user_role == "owner" &&
                        <Stack direction="row" spacing={2}>
                            <Formats details={settings as any} />
                        </Stack>
                    }
                    {users?.user_role == "owner" &&
                        <Stack direction="row" spacing={2}>
                            <Currency details={settings as any} />
                        </Stack>
                    }
                </Stack>
            }

        </Box>
    )
}

export default Settings;