import { useQuery } from 'react-query';
import { useDataProvider } from 'react-admin';

const useVehicleStatus = (vehicleId: any) => {

    const dataProvider = useDataProvider();

    return useQuery(
        ['vehicle-status', vehicleId],
        () =>
            dataProvider.getOne('gps-track/vehicle-status', { id: vehicleId })
                .then(({ data }) => data),
        {
            enabled: !!vehicleId,
            refetchInterval: 10000,
            refetchIntervalInBackground: true,
            keepPreviousData: true,
        }
    );

};

export default useVehicleStatus;