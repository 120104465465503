import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography } from '@mui/material';
import { useLogout, usePermissions } from 'react-admin';
import Person2Icon from '@mui/icons-material/Person2';
import { useGetOne } from 'react-admin';
import useVehicleStatus from '../../../services/vehicles-status';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';

const TaxiStatus = ({ gpsdata, showtext }: any) => {

    const { data, isLoading, error } = useVehicleStatus(gpsdata?.id);

    const variables = React.useMemo(() => {

        const vars: Record<string, any> = {};

        data?.variables?.forEach((variable: any) => {
            if (variable?.name) {
                vars[variable.name] = variable?.value || "False";
            }
        });

        return vars;

    }, [data]);

    return (
        <Box sx={{ mr: 1 }}>
            {variables &&
                <Box display="flex">
                    {variables["Ignition"] &&
                        <Box sx={{ width: 12, height: 12, backgroundColor: variables["Ignition"] == "True" ? "#00ff00" : "gray", borderRadius: 0.5, mt: 0.5, mr: 0.5 }}>
                            {variables["Ignition"] == "True" ?
                                <Typography></Typography>
                                :
                                <Typography fontSize={8} color="#222" textAlign="center" fontWeight="bold">P</Typography>
                            }
                        </Box>
                    }
                    {variables["Taximeter"] &&
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", ml: 0.5 }}>
                            {(variables["Taximeter"] == "True" && variables["Ignition"] == "True") ?
                                <DirectionsCarFilledIcon sx={{ color: "#ff4d4d", fontSize: 20 }} />
                                :
                                <DirectionsCarFilledIcon sx={{ color: "#00ff00", fontSize: 20 }} />
                            }
                        </Box>
                    }
                    {variables["Pasagjer"] &&
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", ml: 0.5 }}>
                            <Person2Icon sx={{
                                color: variables["Ignition"] == "True" && variables["Pasagjer"] == "True" ? "#ff4d4d" : "#00ff00"
                            }} style={{ fontSize: 20 }} />
                        </Box>
                    }
                </Box>
            }
        </Box>
    );

};

export default TaxiStatus;