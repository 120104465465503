import * as React from 'react';
import { Box, Typography, Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Backdrop } from '@mui/material';
import { useListContext, ListBase, Pagination, Loading, LoadingIndicator, useGetList } from 'react-admin';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditIcon from '@mui/icons-material/Edit';
import OrderStatus from './OrderStatus';
import { Marker, AdvancedMarker, Map, MapCameraChangedEvent, useMap } from '@vis.gl/react-google-maps';
import Slider from '@mui/material/Slider';
import { BASE_URL } from '../../../config';
import { format, addHours, differenceInMinutes } from 'date-fns';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import FlagIcon from '@mui/icons-material/Flag';

const center = {
    lat: 41.32900247531315,
    lng: 19.817979844617586,
};

interface Props {
    date: any;
    vehicle: any;
}

const CompletedOrders: React.FC<Props> = ({ date, vehicle }) => {
    return (
        <ListBase
            resource="taxi-orders"
            perPage={10}
            sort={{ field: 'date', order: 'DESC' }}
            filter={{ status: "COMPLETED", date: format(new Date(date), "yyyy-MM-dd"), vehicleId: vehicle ? vehicle : null }}
        >
            <OrderTable />
        </ListBase>
    );
};

const OrderTable = () => {

    const { data: orders, isLoading } = useListContext();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedOrder, setSelectedOrder] = React.useState<any>(null);

    const map: any = useMap();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [polylines, setPolylines] = React.useState<any>([]);

    const [currentPosition, setCurrentPosition] = React.useState(polylines[0]);
    const [sliderValue, setSliderValue] = React.useState(0);

    const { data: vehicles, total, isLoading: isLoadingVehicles, error: errorVehicles, refetch } = useGetList(
        'gps-track/track-vehicles',
        {
            pagination: { page: 1, perPage: 23 },
            sort: { field: 'date', order: 'DESC' },
            filter: {}
        }
    );

    const getGpsVehicleDetailsId = (order: any) => {

        if (vehicles) {

            for (let index = 0; index < vehicles.length; index++) {
                const element = vehicles[index];
                if (element.id === order.vehicle_id) {
                    handleRowClick(order, element);
                }
            }

        }

    }

    const handleRowClick = (order: any, element: any) => {

        setLoading(true);
        setPolylines([]);

        const url = `${BASE_URL}/gps-track/users/${element.gpsData.id}/tracks/${format(new Date(order.pickup_time), 'yyyy-MM-dd')}?startTime=${format(addHours(new Date(order.pickup_time), 0), 'HH:mm:ss')}&endTime=${format(addHours(new Date(order.dropoff_time), 0), 'HH:mm:ss')}&filtered=true`;

        fetch(url, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                if (element.velocity.groundSpeed > 0) {
                    console.log(element);
                    setOpenDialog(true);
                    setLoading(false);
                    setPolylines((prevPolylines: any) => [...prevPolylines, element]);
                }
            }
        }).catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });

    };

    React.useEffect(() => {
        const index = Math.floor((sliderValue / 100) * (polylines.length - 1));
        setCurrentPosition(polylines[index]);
    }, [sliderValue, polylines]);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedOrder(null);
    };

    React.useEffect(() => {

        if (polylines.length > 0) {

            const bounds = new google.maps.LatLngBounds();

            const pl = [
                new google.maps.Polyline({
                    path: polylines.map((item: any) => ({
                        lat: item.position.latitude,
                        lng: item.position.longitude
                    })),
                    geodesic: true,
                    strokeColor: "#222",
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                }),
            ];


            pl.forEach(polyline => {
                polyline.setMap(map);
                const path = polyline.getPath();
                path.forEach(latLng => {
                    bounds.extend(latLng);
                });
            });

            if (map) {
                map.fitBounds(bounds);
            }

        }

    }, [polylines, map])

    return (
        <Box>
            <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Client</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Destination(s)</TableCell>
                                <TableCell>Time & Duration</TableCell>
                                <TableCell>Assigned to</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {orders?.length > 0 ? (
                            <TableBody>
                                {orders.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => getGpsVehicleDetailsId(row)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <TableCell>{row.client_name || "No Name"}</TableCell>
                                        <TableCell>{row.client_phone || "No Number"}</TableCell>
                                        <TableCell>
                                            {row.pickup && row.dropoff &&
                                                <>
                                                    {row.pickup.length > 50
                                                        ? row.pickup.substring(0, 50) + "..."
                                                        : row.pickup}
                                                    {" - "}
                                                    {row.dropoff.length > 50
                                                        ? row.dropoff.substring(0, 50) + "..."
                                                        : row.dropoff}
                                                </>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Box>
                                                <Typography sx={{ color: "gray", fontSize: 12 }}>{format(new Date(row.pickup_time), "dd, MMMM yyyy")}</Typography>
                                                <Typography sx={{ color: "#ddd", fontSize: 13 }}>{format(new Date(row.pickup_time), "HH:mm a") + " - " + format(new Date(row.dropoff_time), "HH:mm a")}</Typography>
                                                <Typography sx={{ color: "orange", fontSize: 13 }}>
                                                    {differenceInMinutes(new Date(row.dropoff_time), new Date(row.pickup_time))} mins
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Chip label={`${row.driver.name} - ${row.driver.vehicle.nickname}`} />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                startIcon={<EditIcon />}
                                                href={`/#/taxi-orders/edit/${row.id}`}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <Box p={2}>
                                <Typography color="gray">No data available</Typography>
                            </Box>
                        )}
                    </Table>
                </TableContainer>
                <Pagination />
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="xl" PaperProps={{
                sx: {
                    width: 1600
                }
            }}>
                <Map
                    mapId={'bf51a910020fa25a'}
                    style={{ width: "100%", height: "100vh" }}
                    defaultZoom={13}
                    defaultCenter={center}
                    fullscreenControl={false}
                    streetViewControl={false}
                    mapTypeControl={false}
                    zoomControl={false}
                    onCameraChanged={(ev: MapCameraChangedEvent) =>
                        console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
                    }
                >
                    {polylines.length > 0 &&
                        <>
                            <AdvancedMarker
                                position={{ lat: polylines[0].position.latitude, lng: polylines[0].position.longitude }}
                                title={'AdvancedMarker that opens an Infowindow when clicked.'}
                            >
                                <Box sx={{ height: 20, pl: 2, pr: 2, backgroundColor: "#333", borderRadius: 15 }}>
                                    <Typography fontSize={13}>Start</Typography>
                                </Box>
                            </AdvancedMarker>
                            <AdvancedMarker
                                position={{ lat: polylines[polylines.length - 1].position.latitude, lng: polylines[polylines.length - 1].position.longitude }}
                                title={'AdvancedMarker that opens an Infowindow when clicked.'}
                            >
                                <Box sx={{}}>
                                    <WhereToVoteIcon sx={{ color: "#222", fontSize: 60 }} />
                                </Box>
                            </AdvancedMarker>
                            {currentPosition &&
                                <AdvancedMarker
                                    position={{ lat: currentPosition.position.latitude, lng: currentPosition.position.longitude }}
                                    title={'AdvancedMarker that opens an Infowindow when clicked.'}
                                >
                                    <Box sx={{ height: 30, width: 30, backgroundColor: "#FF0000", borderRadius: 15 }}>
                                        <DirectionsCarIcon sx={{ fontSize: 20, ml: 0.62, mt: 0.35 }} />
                                    </Box>
                                </AdvancedMarker>
                            }
                        </>
                    }
                </Map>
                <Box sx={{ display: "flex", backgroundColor: "#333", position: "absolute", bottom: 30, borderRadius: 5, right: 80, width: "90%", height: 80 }}>
                    {currentPosition &&
                        <Box sx={{ flexGrow: 1 }}>
                            <Box sx={{ mt: 2, width: "80%", ml: 5 }}>
                                <Slider
                                    aria-label="Polyline Position"
                                    value={sliderValue}
                                    onChange={handleSliderChange}
                                    defaultValue={0}
                                    color="primary"
                                />
                                <Box sx={{ display: "flex" }}>
                                    <Typography fontSize={12} sx={{ flexGrow: 1, fontWeight: "bold" }}>Speed: {Math.round(currentPosition.variables.speed * 3.6)} kmh</Typography>
                                    <Typography fontSize={12}>Time: {format(new Date(`${currentPosition.utc}`), 'dd MMM yyyy, HH:mm:ss')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                    <Box sx={{ mr: 2.5, mt: 3 }}>
                        <Button onClick={() => {
                            handleCloseDialog();
                            setPolylines([])
                        }}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default CompletedOrders;