import { Layout, LayoutProps } from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppBar from './AppBar';
import Menu from './Menu';

export default (props: LayoutProps) => {

    const hideLayout = window.location.hash === '#/';

    return (
        <Layout
            {...props}
            appBar={hideLayout ? () => null : AppBar}
            sidebar={Menu}
        />
    );

};