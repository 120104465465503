import * as React from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Button, Typography, TextField, Checkbox, FormControlLabel, MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { useCreate, usePermissions, useNotify, useRefresh, useGetOne, useUpdate } from 'react-admin';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from "react-router-dom";

const EditOrder = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { id } = useParams();

    const { permissions } = usePermissions();

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const [update, { isLoading, error, data }] = useUpdate();

    const { data: orderDetails, isLoading: isLoadingOrderDetails, error: errorOrderDetails, refetch } = useGetOne(
        "taxi-orders",
        {
            id: `${id}`
        }
    )

    const [status, setStatus] = React.useState("");

    const handleChange = (event: any) => {
        setStatus(event.target.value);
    };

    const { control, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {
        data.status = status;
        console.log(data);
        update('taxi-orders', { data: data, id: id });
    };

    if (data) {
        notify("New driver added successfully", { type: "success" });
        navigate("/taxi-orders");
        refresh();
    }

    if (error) {
        notify("Driver was not added successfully", { type: "error" });
    }

    React.useEffect(() => {
        if (orderDetails) {
            setStatus(orderDetails?.status);
        }
    }, [orderDetails])

    return (
        <Box sx={{ mt: 6, p: 4, background: "linear-gradient(145deg, #222, #333)", borderRadius: 5, ml: 4, mr: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography fontSize={22} ml={1} color="#ddd" fontWeight="bold">Edit Order</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <Controller
                            name="client_name"
                            control={control}
                            defaultValue={orderDetails?.client_name}
                            render={({ field }) => (
                                <TextField {...field} label="Client Name" fullWidth />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <Controller
                            name="client_phone"
                            control={control}
                            defaultValue={orderDetails?.client_phone}
                            render={({ field }) => (
                                <TextField {...field} label="Client Phone" fullWidth />
                            )}
                        />
                    </Grid>
                </Grid>

                <FormControl sx={{ mt: 3, minWidth: 200 }}>
                    <InputLabel id="demo-simple-select-helper-label">Order status</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={status}
                        label="Order status"
                        onChange={handleChange}
                    >
                        <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
                        <MenuItem value="COMPLETED">Completed</MenuItem>
                        <MenuItem value="ACCEPTED">Accepted</MenuItem>
                        <MenuItem value="CANCELLED">Cancelled</MenuItem>
                        <MenuItem value="ASSIGNED">Assigned</MenuItem>
                    </Select>
                    <FormHelperText>Change order status</FormHelperText>
                </FormControl>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={1}>
                        <Button type="submit" variant="contained" color="primary" size="large" fullWidth sx={{ borderRadius: 15, backgroundColor: "#ef4e55" }}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>

            </form>
        </Box >
    );

};

export default EditOrder;