import * as React from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Typography, TextField, Chip, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, List, ListItem, ListItemText, ListItemButton, Autocomplete } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetList, useUpdate, useNotify, useRefresh } from 'react-admin';

const ReservationsOrders = () => {
    return (
        <Box sx={{ p: 7 }}>

        </Box>
    );
};

export default ReservationsOrders;