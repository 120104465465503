import * as React from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography, Button, Chip } from '@mui/material';
import { ListBase, Pagination, useListContext, usePermissions } from 'react-admin';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetList } from 'react-admin';
import { format } from 'date-fns';

const TaxiDrivers = () => {
    return (
        <ListBase
            resource="drivers"
            perPage={10}
            sort={{ field: 'date', order: 'DESC' }}
            filter={{}}
        >
            <TableDrivers />
        </ListBase>
    );
};

const TableDrivers = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { permissions } = usePermissions();

    const { data: drivers, total, isLoading, error } = useListContext();

    const getStatusChip = (status: string) => {

        let color: 'default' | 'primary' | 'secondary' | 'error' | 'success' | 'warning' = 'default';
        let label = '';

        switch (status) {
            case 'AVAILABLE':
                color = 'default';
                label = 'Available';
                break;
            case 'BUSY':
                color = 'warning';
                label = 'Busy';
                break;
            case 'OFFLINE':
                color = 'error';
                label = 'Offline';
                break;
            default:
                label = 'Unknown';
                break;
        }

        return <Chip label={label} color={color} />;
    };

    return (
        <Box sx={{ mt: isSmall ? 0 : 7, p: 4, background: "linear-gradient(145deg, #222, #333)", borderRadius: 10, ml: 4, mr: 4 }}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography fontSize={22} ml={1} color="#ddd" fontWeight="bold">Drivers</Typography>
                <Button href="/#/create-driver" variant="contained" color="primary" size="large" sx={{ borderRadius: 15, backgroundColor: "#ef4e55" }}>
                    New Driver
                </Button>
            </Box>

            {isLoading ? (
                <Typography>Loading...</Typography>
            ) : (
                <>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Driver Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Driver Phone</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            {/* @ts-ignore */}
                            {drivers?.length > 0 ? (
                                <TableBody>
                                    {drivers?.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row.drivers[0].name}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.drivers[0].phone}</TableCell>
                                            <TableCell>{getStatusChip(row.drivers[0].status)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <Box p={2}>
                                    <Typography color="gray">No data available</Typography>
                                </Box>
                            )}
                        </Table>
                    </TableContainer>
                    {/* Pagination Control */}
                    <Pagination />
                </>
            )}
        </Box>
    );

};

export default TaxiDrivers;