import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField } from '@mui/material';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useForm, Controller } from 'react-hook-form';
import { useCreate, usePermissions, useNotify, useRefresh } from 'react-admin';
import { useNavigate } from "react-router-dom";

const ConfirmOrder = ({ inputValuePickup, inputValueDropOff, pickupLatitude, pickupLongitude, dropOffLatitude, dropOffLongitude }: any) => {

    const [open, setOpen] = useState(false);

    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();

    const [pickupDateValue, setPickupDateValue] = React.useState<Date | any>(
        new Date()
    );

    const [pickupTimeValue, setPickupTimeValue] = React.useState<Date | any>(
        new Date()
    );

    const { control, handleSubmit } = useForm();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [create, { isLoading, error, data }] = useCreate();

    const mergeDateAndTime = (date: any, time: any) => {
        if (!date || !time) return null;

        const dateObj = new Date(date);
        const timeObj = new Date(time);

        if (isNaN(dateObj.getTime()) || isNaN(timeObj.getTime())) {
            console.error('Invalid date or time');
            return null;
        }

        const combinedDateTime = new Date(
            dateObj.getFullYear(),
            dateObj.getMonth(),
            dateObj.getDate(),
            timeObj.getHours(),
            timeObj.getMinutes(),
            timeObj.getSeconds()
        );

        return combinedDateTime;
    };

    const onSubmit = (data: any) => {
        data.fixed_fee = true;
        data.pickup_time = mergeDateAndTime(pickupDateValue, pickupTimeValue);
        data.status = "NEW_ORDER";
        data.pickup = inputValuePickup;
        data.dropoff = (dropOffLatitude && dropOffLongitude) === null ? "" : inputValueDropOff;
        data.pickup_latitude = pickupLatitude;
        data.pickup_longitude = pickupLongitude;
        data.dropoff_latitude = dropOffLatitude;
        data.dropoff_longitude = dropOffLongitude;
        create('taxi-orders', { data: data });
        handleClose();
    };

    if (data) {
        notify("New Order added successfully", { type: "success" });
        navigate("/taxi-orders");
        refresh();
    }

    if (error) {
        notify("Order was not added successfully", { type: "error" });
    }

    return (
        <>
            <Button
                onClick={handleOpen}
                variant="contained"
                size="large"
                sx={{
                    background: "linear-gradient(145deg, #ef4e55, #ff9194)",
                    color: "#fff",
                    pl: 3,
                    pr: 3,
                    height: 50,
                    borderRadius: 2,
                    boxShadow: '0 8px 8px rgba(0, 0, 0, 0.4)',
                    transition: 'background-color 0.3s, opacity 0.3s',
                    '&:hover': {
                        backgroundColor: "#ef4e55",
                        color: "#fff"
                    },
                }}
            >
                Continue
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                PaperProps={{
                    sx: {
                        backgroundColor: "#000",
                        borderRadius: 10,
                        p: 2
                    }
                }}
            >
                <DialogTitle sx={{ color: "gray" }}>Enter Pickup Details</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} sx={{ mt: -2 }}>
                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="Pickup Date *"
                                        value={pickupDateValue}
                                        onChange={(newValue: any) => {
                                            setPickupDateValue(newValue);
                                        }}
                                        renderInput={(params: any) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileTimePicker
                                        label="Pickup Time *"
                                        value={pickupTimeValue}
                                        onChange={(newValue: any) => {
                                            setPickupTimeValue(newValue);
                                        }}
                                        renderInput={(params: any) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={5}>
                                <Controller
                                    name="client_name"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField {...field} label="Client Name" fullWidth />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Controller
                                    name="client_phone"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField type="number" {...field} label="Client Phone" fullWidth />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit(onSubmit)} variant="contained">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ConfirmOrder;