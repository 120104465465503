import * as React from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography, TextField, IconButton, ListItemButton, List, ListItem, ListItemSecondaryAction, ListItemText, Button, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useNotify, useRefresh, useGetList, useGetOne, useDataProvider, useTheme } from 'react-admin';
import GooglePlacesAutocomplete from '../../../layout/components/GoogleMapAutocomplete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OverviewTab from '../../gps-track/OverviewTab';
import TripsTab from '../../gps-track/TripsTab';
import EventsTab from '../../gps-track/EventsTab';
import VehicleStatus from '../../gps-track/VehicleStatus';
import VehicleTaxiStatus from '../../gps-track/VehicleTaxiStatus';
import ConfirmOrder from './ConfirmOrder';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import { Check } from '@mui/icons-material';
import { Marker, AdvancedMarker, Map, MapCameraChangedEvent, useMap } from '@vis.gl/react-google-maps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import TaxiStatus from '../components/TaxiStatus';
import useVehicles from '../../../services/vehicles';
import MarkerStatus from '../components/MarkerStatus';
import ExtractLatLng from './ExtractGoogleMapsInput';

const mapContainerStyle = {
    width: "100%",
    height: "100vh",
};

const center = {
    lat: 41.32900247531315,
    lng: 19.817979844617586,
};

const DashboardDispatch = () => {

    const map = useMap();
    const { data: vehicles, isLoading, error } = useVehicles();

    const [trafficLayer, setTrafficLayer] = React.useState<any>(null);
    const [showTraffic, setShowTraffic] = React.useState(false);
    const [showRoadMap, setShowRoadMap] = React.useState(false);

    const [inputValuePickup, setInputValuePickup] = React.useState('');
    const [inputValueDropOff, setInputValueDropoff] = React.useState('');

    const [pickupLatitude, setPickupLatitude] = React.useState<any>(null);
    const [pickupLongitude, setPickupLongitude] = React.useState<any>(null);

    const [dropOffLatitude, setDropOffLatitude] = React.useState<any>(null);
    const [dropOffLongitude, setDropOffLongitude] = React.useState<any>(null);

    const [markerData, setMarkerData] = React.useState<any>({});

    const handleClickMarker = async (marker: any) => {

        setMarkerData(marker);

        if (map) {
            map.panTo({
                lat: marker.gpsData.trackPoint.position.latitude,
                lng: marker.gpsData.trackPoint.position.longitude
            });

            map.setZoom(15);
        }


    }

    const cordinatesFromUrl = (cords: any) => {
        latLngDropoff(cords);
    }

    const latLngPickup = (data: any) => {

        setPickupLatitude(data.lat);
        setPickupLongitude(data.lng);

        if (map) {
            map.panTo({
                lat: data.lat,
                lng: data.lng
            });

            map.setZoom(13);
        }

    }

    const latLngDropoff = (data: any) => {

        setDropOffLatitude(data.lat);
        setDropOffLongitude(data.lng);

        if (map) {
            map.panTo({
                lat: data.lat,
                lng: data.lng
            });

            map.setZoom(13);
        }

    }

    const inputValueAddressPickup = (address: any) => {
        setInputValuePickup(address);
    }

    const inputValueAddressDropOff = (address: any) => {
        setInputValueDropoff(address)
    }

    const handleToggleLayer = (layer: any, setLayerVisible: any, isEnabled: any) => {
        if (layer) {
            if (isEnabled) {
                layer.setMap(map);
            } else {
                layer.setMap(null);
            }
        }
        setLayerVisible(isEnabled);
    };

    const handleToggleMapType = (isEnabled: any) => {
        if (isEnabled) {
            if (map) {
                map.setMapTypeId(google.maps.MapTypeId.HYBRID);
            }
        } else {
            if (map) {
                map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
            }
        }
        setShowRoadMap(isEnabled);
    };

    React.useEffect(() => {

        if (window.google) {

            if (pickupLatitude && dropOffLatitude) {

                const directionsService = new window.google.maps.DirectionsService();
                const directionsRenderer = new window.google.maps.DirectionsRenderer({
                    map: map,
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: '#ef4e55',
                        strokeOpacity: 1.0,
                        strokeWeight: 4,
                    },
                });

                const request = {
                    origin: new window.google.maps.LatLng(pickupLatitude, pickupLongitude),
                    destination: new window.google.maps.LatLng(dropOffLatitude, dropOffLongitude),
                    travelMode: window.google.maps.TravelMode.DRIVING,
                };

                directionsService.route(request, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        directionsRenderer.setDirections(result);
                    } else {
                        console.error('Directions request failed due to ' + status);
                    }
                });

            }

        }

    }, [pickupLatitude, pickupLongitude, dropOffLatitude, dropOffLongitude])

    React.useEffect(() => {
        setTrafficLayer(new google.maps.TrafficLayer());
    }, [map]);

    return (
        <Box sx={{ mt: -6 }}>
            <Map
                mapId={'bf51a910020fa25a'}
                style={{ width: "100%", height: "100vh" }}
                defaultZoom={14}
                defaultCenter={center}
                fullscreenControl={false}
                streetViewControl={false}
                mapTypeControl={false}
                zoomControl={false}
                onCameraChanged={(ev: MapCameraChangedEvent) =>
                    console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
                }
            >
                {vehicles && vehicles.data.map((item) => (
                    <MarkerStatus item={item} />
                ))}
                <Marker
                    position={{
                        lat: pickupLatitude,
                        lng: pickupLongitude,
                    }}
                    onDragEnd={(event: any) => {
                        const newLat = event.latLng.lat();
                        const newLng = event.latLng.lng();
                        setPickupLatitude(newLat);
                        setPickupLongitude(newLng);
                    }}
                    icon={{
                        url: 'https://cdn-icons-png.flaticon.com/512/5216/5216405.png',
                        scaledSize: new google.maps.Size(55, 55),
                    }}
                    draggable
                />
                <Marker
                    position={{
                        lat: dropOffLatitude,
                        lng: dropOffLongitude,
                    }}
                    onDragEnd={(event: any) => {
                        const newLat = event.latLng.lat();
                        const newLng = event.latLng.lng();
                        setDropOffLatitude(newLat);
                        setDropOffLongitude(newLng);
                    }}
                    icon={{
                        url: 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png',
                        scaledSize: new google.maps.Size(55, 55),
                    }}
                    draggable
                />
            </Map>
            <Box sx={{ position: "absolute", top: 0, left: 80 }}>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ mt: 1.8, ml: 3 }}>
                        <GooglePlacesAutocomplete title="Pick-Up location" iValue={inputValueAddressPickup} setLatLng={latLngPickup} changedLatitude={pickupLatitude} changedLongitude={pickupLongitude} />
                    </Box>
                    <Box sx={{ mt: 1.8, ml: 2 }}>
                        <GooglePlacesAutocomplete title="Drop-Off location" iValue={inputValueAddressDropOff} setLatLng={latLngDropoff} changedLatitude={dropOffLatitude} changedLongitude={dropOffLongitude} />
                    </Box>
                    {pickupLatitude && pickupLongitude &&
                        <Box sx={{ mt: 2, ml: 2 }}>
                            <ConfirmOrder inputValuePickup={inputValuePickup} inputValueDropOff={inputValueDropOff} pickupLatitude={pickupLatitude} pickupLongitude={pickupLongitude} dropOffLatitude={dropOffLatitude} dropOffLongitude={dropOffLongitude} />
                        </Box>
                    }
                </Box>
                <Box sx={{ ml: -49, mt: 2 }}>
                    <ExtractLatLng setCordinatesFromUrl={cordinatesFromUrl} />
                </Box>
            </Box>
            <Box sx={{ pl: 3, pr: 1, background: "linear-gradient(145deg, #222, #333)", zIndex: 1, position: "absolute", bottom: 10, left: 100, borderRadius: 5 }}>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showTraffic}
                                onChange={(e: any) => handleToggleLayer(trafficLayer, setShowTraffic, e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Show Traffic"
                    />
                    <FormControlLabel
                        sx={{ ml: 1 }}
                        control={
                            <Switch
                                checked={showRoadMap}
                                onChange={(e: any) => handleToggleMapType(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Hybrid View"
                    />
                </FormGroup>
            </Box>
            <Box
                sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    backgroundColor: "#222",
                    width: 250,
                    height: "100%",
                    pt: 2,
                    overflowY: "auto",
                    maxHeight: "100vh",
                }}
            >
                <Typography sx={{ ml: 2 }} color="gray">
                    Vehicles ({vehicles?.data.length})
                </Typography>
                <nav aria-label="main mailbox folders">
                    <List>
                        {vehicles?.data.map((item, index) => (
                            <ListItem disablePadding onClick={() => handleClickMarker(item)} key={index}>
                                <ListItemButton>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                        <Box>
                                            <TaxiStatus gpsdata={item.gpsData} />
                                        </Box>
                                        <Box sx={{ flexGrow: 1, ml: 1 }}>
                                            <Typography color="#ddd">{item.nickname ? item.nickname : ""}</Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                            <Typography color={Math.round(item.gpsData.calculatedSpeed * 3.6) >= 100 ? "#ef4e55" : Math.round(item.gpsData.calculatedSpeed * 3.6) === 0 ? "gray" : "#bcd96e"} fontSize={12}>
                                                {Math.round(item.gpsData.calculatedSpeed * 3.6)} kmh
                                            </Typography>
                                        </Box>
                                    </Box>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </nav>
            </Box>
        </Box>
    );
};

export default DashboardDispatch;